<template>
  <v-container fluid>
    <v-row dense>
      <!-- IMAGE -->
      <v-col class="d-flex justify-center" cols="12" md="6" order="1" order-md="2" style="background-color: white">
          <v-img
            v-if="images.length === 1"
            :src="images[0]"
            contain
          ></v-img>
          <v-window v-else v-model="carousel" touchless>
            <v-window-item v-for="(image, i) in images" :key="i" :value="i">
              <v-img
                :src="image"
                contain
              ></v-img>
            </v-window-item>
          </v-window>
      </v-col>
      <!-- TEXT -->
      <v-col cols="12" md="6" order="2" order-md="1">
        <v-row dense>
          <v-col cols="12" v-html="program.html">
          </v-col>
          <v-col cols="12">
            <v-row dense>
              <v-col cols="6">
                <v-btn color="color3 color3Text--text" :to="{name: 'new-member'}" block class="pa-12">
                  <v-icon class="mr-4" v-if="!$vuetify.breakpoint.xsOnly">fas fa-user-plus</v-icon>
                  Join Now
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn color="color3 color3Text--text" :to="{name: 'member-search'}" block class="pa-12">
                  <v-icon class="mr-4" v-if="!$vuetify.breakpoint.xsOnly">fas fa-search</v-icon>
                  Find My Number
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ['program'],
  computed: {
    images () {
      return this.program && this.program.imageList.split(',')
    }
  }
}
</script>

<style>

</style>
